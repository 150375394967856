<template>
    <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div id="doctor-ask" style="min-height: 100vh;" ref="listView">
			<div class="aq-list">
				<div class="aq-item" v-for="(item, index) in dataList" :key="index">
					<div class="question-item">
						<div class="question-item-head">
							<img src="../../../assets/imgs/mobile/img_yonghu@2x.png" alt="" class="question-avatar">
							<div class="question-item-head-r">
								<div class="question-user-info">
									<span class="gender" v-text="item.gender == 1 ? '男' : '女'"></span>
									<span class="age" v-text="item.age + '歲'"></span>
									<span class="add-time" v-text="item.createdtime"></span>
								</div>
								<h3 class="question-item-title">
									<router-link :to="'/ask/list?IllCate='+item.illnesscategoryid" class="q-category" v-text="'#'+item.illnesscategorycode"></router-link>
									<router-link :to="'/ask/detail/'+item.askid" v-text="item.title"></router-link>
								</h3>
							</div>
						</div>
						<p class="question-item-content" v-text="'問題描述:  '+item.note"></p>
					</div>
					<div class="aq-operate">
						<div class="btn-sea-all" @click="extrendAnswer(item)">{{askidList.indexOf(item.askid) == -1 ? (item.answerList.length + '條回復') : '收起回复'}}</div>
					</div>
				</div>
			</div>
            <div class="load-more" v-if="isLoadMore">
				<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<span class="text">正在加载...</span>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
        </div>
        <loading v-if="loading"/>
    </van-pull-refresh>
</template>

<script>
    import Loading from '@/layouts/Loading.vue'
	import doctor_detail from '@/views/mixins/doctor_detail'
	export default {
        mixins: [doctor_detail],
        props: {
            doctor: null,
        },
        components: {
            Loading,
        },
        data(){
            return {
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataList: [],
                askidList: [],
				loading: true,
				isRefresh: false,
				isLoadMore: false,
                isLoadFinished: false,
				maxPage: 0,
            }
        },
        mounted: function(){
            this.onRefresh()
        },
        methods: {
            onRefresh: function(){
                this.loading = true
                this.getDoctorAskList({
                    doctorglobalid: this.doctor.globaluserid,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                }, (data, error)=>{
                    this.loading = false
					this.isRefresh = false
					this.dataList = (data && data.list) || []
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
						if(!error) {
							this.isLoadFinished = true
						}
					}
                })
            },
            handleScroll (event) {
				if(this.$refs.listView.clientHeight < window.innerHeight) return
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.getDoctorAskList({
							doctorglobalid: this.doctor.globaluserid,
							pageNo: this.pageNo,
							pageSize: this.pageSize,
						}, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			},
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
		watch: {
        }
    }
</script>

<style lang="scss" scoped>
    #doctor-ask {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        padding-bottom: 60px;
    }

    .aq-list {
        width: 100%;
        display: flex;
        flex-direction: column;
		background-color: #fff;
    }

	.aq-list .aq-item .question-item {
		margin: 0px;
	}

	.aq-list .aq-item .question-item .question-avatar {
		width: 32px;
		height: 32px;
		display: none;
	}

	.aq-list .aq-item .question-item .question-user-info {
		flex: 1;
		display: flex;
		flex-direction: row;
		height: 23px;
		line-height: 23px;
		margin-bottom: 25px;
		width: auto;
		font-size: 24px;
		font-weight: 400;
		color: #969696;
	}

	.aq-list .aq-item .question-item .question-user-info > .gender {
		margin-left: 0px;
	}

	.aq-list .aq-item .question-item .question-user-info > .age {
		margin-left: 08px;
	}

	.aq-list .aq-item .question-item .question-user-info > .add-time {
		margin-left: 28px;
	}

	.aq-list .aq-item .question-item .question-item-head-r {
		margin-top: 04px;
	}

	.aq-list .aq-item .question-item .question-item-title  {
		font-size: 34px;
		font-weight: bold;
		color: #231F20;
	}
	
	.aq-list .aq-item .question-item .question-item-title > .q-category {
		color: #FF8000;
		margin-right: 8px;
	}

	.aq-list .aq-operate {
		margin: 50px 0px 0px 0px;
		display: flex;
		flex-direction: row;
		/* border-bottom: 1px solid #E8E8E8; */
		padding-bottom: 32px;
		position: relative;
	}

	.aq-list.my-aq .aq-operate {
		margin-top: 47px;
	}

	.aq-list .aq-item:last-child .aq-operate {
		border-bottom: 0;
	}

	.aq-list .aq-operate .btn-sea-all {
		height: 25px;
		line-height: 25px;
		position: relative;
		padding-left: 48px;
		padding-right: 0px;
		font-weight: 400;
		color: #231F20;
		font-size: 26px;
		background-color: transparent;
		border-radius: 0px;
		display: inline-block;
		cursor: pointer;
		margin-right: 0px;
	}

	.aq-list .aq-operate .btn-sea-all::after {
		content: '';
		position: absolute;
		left: 0px;
		top: 0px;
		width: 25px;
		height: 23px;
		background-image: url(../../../assets/imgs/mobile/img_xiaoxi@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.aq-list .aq-operate .btn-sea-all.extrend {
		padding-left: 0;
		color: transparent;
		margin-right: 126px;
	}

	.aq-list .aq-operate .btn-sea-all.extrend::after {
		content: '收起回復';
		position: absolute;
		left: 17px;
		top: 0px;
		width: 105px;
		height: auto;
		font-size: 26px;
		line-height: 25px;
		color: #36C4D0;
		font-weight: bold;
		background-image: none;
	}

	.aq-list .aq-item {
		padding: 31px 57px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #ebebeb;
	}

	.aq-list .aq-operate .btn-sea-all.extrend::before {
		display: none;
	}

	.aq-list .aq-operate .btn-unshift-q {
		position: absolute;
		top: 0px;
		left: 2.32px;
		height: 25px;
		line-height: 25px;
		padding-left: 40px;
		padding-right: 0px;
		font-weight: 400;
		color: #231F20;
		font-size: 26px;
		background-color: transparent;
		border-radius: 0px;
		display: inline-block;
		cursor: pointer;
		margin-right: 0px;
	}

	.aq-list .aq-operate .btn-unshift-q::after {
		content: '';
		position: absolute;
		left: 0px;
		top: -02px;
		width: 24px;
		height: 22px;
		background-image: url(../../../assets/imgs/mobile/icon_zhuiwen@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.aq-list .aq-operate .btn-unshift-q.disable::after {
		background-image: url(../../../assets/imgs/mobile/img_zhuiwenblock@2x.png);
	}

	.aq-list.my-aq .aq-item .question-item .question-item-content {
		margin-top: 0px;
	}

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}

	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
</style>
